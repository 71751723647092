import { Controller } from '@hotwired/stimulus'

import Swiper from 'swiper'
import { Autoplay, FreeMode } from 'swiper/modules'

export default class extends Controller {
  connect () {
    this.swiper = new Swiper(this.element, this.swiperOptions)
  }

  get swiperOptions () {
    return {
      modules: [Autoplay, FreeMode],
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      speed: 5000,
      autoplay: {
        enabled: true,
        delay: 0,
        disableOnInteraction: false
      },
      freeMode: {
        enabled: true,
        momentum: false,
      }
    }
  }
}
