import { Controller } from '@hotwired/stimulus'

import { thumbHashToDataURL } from 'thumbhash'

export default class extends Controller {
  static values = {
    hash: String
  }

  connect () {
    this.element.style.backgroundSize = '100% 100%'
    this.element.style.backgroundImage = `url(${this.dataUrl})`
  }

  get dataUrl () {
    try {
      return thumbHashToDataURL(this.hashBytes)
    } catch {
      console.error('Invalid thumbhash:', this.hashValue)
    }
  }

  get hashBytes () {
    return atob(this.hashValue).split('').map(x => x.charCodeAt(0))
  }
}
