import { Controller } from '@hotwired/stimulus'

import { sampleSize } from 'lodash'

import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

export default class extends Controller {
  static values = {
    url: String,

    count: {
      type: Number,
      default: 20
    }
  }

  static targets = ['swiper', 'container', 'next', 'prev']

  static classes = ['wrapper']

  connect() {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        const ids = sampleSize(data.ids, this.countValue)

        ids.forEach(id => {
          const detection = this.createDetection(id)
          this.containerTarget.appendChild(detection)
        })
      })
      .then(() => {
        this.swiper = new Swiper(this.swiperTarget, this.swiperOptions)
      })
  }

  createDetection (id) {
    const div = document.createElement('div')
    div.classList.add(...this.wrapperClasses)

    const iframe = document.createElement('iframe')
    iframe.classList.add('detection-library-card')
    iframe.src = this.embedUrl(id)
    iframe.loading = 'lazy'

    div.appendChild(iframe)

    return div
  }

  embedUrl (id) {
    return `https://app.birdweather.com/detections/${id}/embed`
  }

  get swiperOptions () {
    return {
      modules: [Navigation],

      loop: true,
      allowTouchMove: false,

      slidesPerView: 1,
      spaceBetween: 12,

      breakpoints: {
        1200: {
          slidesPerView: 4,
          spaceBetween: 20
        },

        992: {
          slidesPerView: 3
        },

        768: {
          slidesPerView: 2
        }
      },

      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      }
    }
  }
}
