import { Controller } from '@hotwired/stimulus'

import Swiper from 'swiper'
import { EffectFade, Thumbs } from 'swiper/modules'

export default class extends Controller {
  static targets = ['swiper', 'thumbnails']
  static values = {
    swiperOptions: Object,
    thumbnailOptions: Object
  }

  connect () {
    if (this.hasThumbnailsTarget) {
      this.thumbnails = new Swiper(this.thumbnailsTarget, this.thumbnailOptions)
    }

    this.swiper = new Swiper(this.swiperTarget, this.swiperOptions)
  }

  get thumbnailOptions () {
    return {
      slidesPerView: 5,
      watchSlidesProgress: true,

      ...this.thumbnailOptionsValue
    }
  }

  get swiperOptions () {
    return {
      modules: [EffectFade, Thumbs],

      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },

      thumbs: {
        swiper: this.thumbnails
      },

      ...this.swiperOptionsValue
    }
  }
}
