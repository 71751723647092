import { Controller } from '@hotwired/stimulus'

const dig = (obj, path) => {
  return path.split(/\./).reduce((acc, k) => acc?.[k] ?? null, obj)
}

export default class extends Controller {
  static targets = [ "stat" ]
  static values = { url: String }

  connect () {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        this.statTargets.forEach(el => {
          const stat = el.dataset.stat
          const value = dig(data.stats, stat)

          el.textContent = this.format(value)
          el.classList.remove('loading')
        })
      })
  }

  format (value) {
    if (value) {
      return value.toLocaleString()
    } else {
      return '?'
    }
  }
}
